body {
    background: #f3f1f2;
    font-family: sans-serif;
}

#root {
    /*margin: 1rem 4rem;*/
}

#root .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
}

/* Snow Theme */
#root .ql-snow.ql-toolbar {
    display: block;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

#root .ql-editor {
    min-height: 18em;
}

.ql-align-center {
    width: 100%;
    text-align: center;
}

p {
    width: 100%;
    text-align: left;
}

.ql-align-right {
    width: 100%;
    text-align: right;
}

.ql-align-justify {
    width: 100%;
    text-align: justify;
}