.articleShort {
    margin: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 5px #ccc;
    text-align: start;
    font-family: Roboto, sans-serif;
}

.article {
    width: 95%;
    margin: 0.5rem;
    padding: 0.5rem;
    text-align: start;
    font-family: Roboto, sans-serif;
}

.thumbnailShort {
    height: 130px;
    width: auto;
    margin-bottom: 0.5em;
    margin-right: 1rem;
    float: left;
}

.thumbnail {
    height: 200px;
    width: auto;
    margin: 0.5em;
    float: right;
}

.title {
    font-size: 1.5em;
    margin-bottom: 0.5em;
    margin-left: 0.5rem;
    text-align: start;
    font-family: Roboto, sans-serif;
}

.author {
    display: inline;
    font-size: 1em;
    color: #525252;
    margin-bottom: 0.5em;
    margin-left: 0.5rem;
    text-align: left;
    font-family: Roboto, sans-serif;
}

.creationDatetime {
    display: inline;
    font-size: 0.8em;
    color: #525252;
    margin-bottom: 0.5em;
    margin-left: 0.5rem;
    font-family: Roboto, sans-serif;
}

.content {
    margin-left: 0.5rem;
    margin-bottom: 0.5em;
    text-indent: 10px;
    font-family: Roboto, sans-serif;
    font-weight: 300;
}

.content::first-line {
    text-indent: 25px;
}

.articleButton {
    margin: 0.5rem;
}

.buttonBox {
    text-align: end;
}